import jQuery from 'jquery';
import 'selectize';

(($) => {

    'use strict';

        
    if(!window.mt){
    	window.mt = {};
	}
	if(!window.fbq){
		window.fbq = '';
	}
  	if(!window.ga){
    	window.ga = '';
	}
  	if(!window.MauticDomain){
    	window.MauticDomain = 'https://wema.marketing.abteilung.ch';
	}


    window.ABT = {

        cookie: {

            create: function (name, value, hours) {
                if (hours) {
                    var date = new Date();
                    date.setTime(date.getTime()+(hours*60*60*1000));
                    var expires = "; expires="+date.toGMTString();
                } else var expires = "";
                document.cookie = name+"="+value+expires+"; path=/";
            },

            read: function (name) {
                var nameEQ = name + "=";
                var ca = document.cookie.split(';');
                for (var i=0; i < ca.length; i++) {
                    var c = ca[i];
                    while (c.charAt(0)==' ') c = c.substring(1,c.length);
                    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
                }
                return null;
            },

            erase: function (name) {
                this.create(name,"",0);
            }
        },


        mt:{
            init: function(){
                this.mid = ABT.cookie.read('mtc_id'),
                this.sid = ABT.cookie.read('mtc_sid'),
                this.did = ABT.cookie.read('mautic_device_id');
            },
            track: function(category, action, label, data){
                if(this.mid && this.sid && this.did){
                    this.title = 'Event: '+ category +' > '+ action +' > '+ label;
                    (data ? this.title + ' > '+ data : '');
                    this.src = MauticDomain+'/mtracking.gif?page_title='+encodeURIComponent(this.title);
                    //console.log(this.src);
                    var img = document.createElement('img');
                        img.style.width = img.style.height  = '1px';
                        img.style.display = 'none';
                        img.src = this.src;

                    var body = document.getElementsByTagName('body')[0];
                        body.appendChild(img);
                }
            },
        },

        basket: {

            init: function(){

                this.ctrl = null,
                this.prod = document.title;

                this.pBtn  = $('.act-add-product');
                this.cBtn  = $('.act-open-cart');
                this.sBtn  = $('#mauticform_input_produktanfrage_submit');

                this.pSel = $('.sel-produkte');
                this.pNum = $('.form-amount')[0].value;
                this.area = $('.sel-textarea input');
                this.theme = 'default';

                this.menu = $('.basket');
                this.ctEl = this.menu.find('.badge');

                // Local storage
                this.cart = JSON.parse(localStorage.getItem('cart')) || {};
                this.opts = JSON.parse(localStorage.getItem('opts')) || [];

                if(this.pSel.length){

                    this.selectizise = this.pSel.selectize({
                        maxItems: null,
                        persist: false,
                        plugins: ['remove_button'],
                        delimiter: ',',
                        sortField: 'name',
                        valueField: 'nid',
                        labelField: 'nid',
                        options: this.getOptions(),
                        render: {
                            item: function(item, escape) {
                                return  (item.from ? '<div class="theme-'+ escape(item.from) +'">' : '') +
                                        (item.nid ? '<div class="item">' + escape(item.nid) + '</div>' : '') +
                                        (item.from ? '</div>' : '');
                            },
                            // option: function(item, escape) {
                            //     var label = item.name || item.email;
                            //     var caption = item.name ? item.email : null;
                            //     return '<div>' +
                            //         '<span class="label">' + escape(label) + '</span>' +
                            //         (caption ? '<span class="caption">' + escape(caption) + '</span>' : '') +
                            //     '</div>';
                            // }
                        },
                        onItemRemove: function(value) {
                            var data = '';

                            if (ABT.basket.cart[value]) {
                                delete ABT.basket.cart[value];
                                data = JSON.stringify(ABT.basket.cart);
                                localStorage.setItem('cart', data);
                            }
                            
                            ABT.basket.updateField();
                            ABT.basket.updateBadge();
                        },
                        onItemAdd: function(value) {
                            var data = '';

                            if (!ABT.basket.cart[value]) {
                                ABT.basket.cart[value] = {};
                            }

                            var nid = $('.form-amount')[0].value;
                            var title = document.title;
                            var name = nid ? nid + ' x ' + title : '1 x ' + title;

                            ABT.basket.cart[value] = {
                                nid: name,
                                name: value,
                                from: ABT.basket.theme
                            };

                            data = JSON.stringify(ABT.basket.cart);
                            localStorage.setItem('cart', data);
                            
                            ABT.basket.updateField();
                            ABT.basket.updateBadge();
                        },
                        onInitialize: function(e){
                            for(var nid in ABT.basket.cart){
                                this.addItem(nid);
                            }
                            window.setTimeout(function(){
                                ABT.basket.updateField();
                                ABT.basket.updateBadge();
                            },500);
                        }
                    });

                    this.ctrl = this.selectizise[0].selectize;
                }

                this.pBtn.on('click', null, {_this: this}, function(e) {
                    // read the value again from the input. Could be empty on init
                    e.data._this.pNum = $('.form-amount')[0].value;
                    
                    var name = e.data._this.pNum ? e.data._this.pNum + ' x ' + e.data._this.prod : '1 x ' + e.data._this.prod;

                    var obj = {
                        num: e.data._this.pNum,
                        name: e.data._this.prod,
                        from: e.data._this.theme,
                        nid: name
                    };
                    
                    e.data._this.setOption(obj);

                    // conversion tracking
                    if(fbq === typeof Function){
                        fbq('track', 'AddToCart', obj);
                    }
                    if(ga === typeof Function){
                        ga('send', 'event', 'button', 'click', 'AddToCart', 1);
                    }
                    if(mt){
                        mt.track('button','click','addToCart', obj.from+':'+obj.name);
                    }
                    // open overlay
                });

                // open cart btn
                this.cBtn.on('click', null, {_this: this}, function(e) {
                    // conversion tracking
                    if(fbq === typeof Function){
                        fbq('track', 'InitiateCheckout');
                    }
                    if(ga === typeof Function){
                        ga('send', 'event', 'button', 'click', 'InitiateCheckout', 1);
                    }
                    if(mt === typeof Function){
                        mt.track('button','click','initiateCheckout');
                    }
                    // open overlay
                });

                // submit btn
                this.sBtn.on('click', null, {_this: this}, function(e) {
                    // conversion tracking
                    if(fbq === typeof Function){
                        fbq('track', 'SubmitApplication');
                    }
                    if(ga === typeof Function){
                        ga('send', 'event', 'button', 'click', 'SubmitOfferRequest', 1);
                    }
                    if(mt === typeof Function){
                        mt.track('button','click','submitOfferRequest');
                    }
                    // close overlay
                });

            },
            updateBadge: function(){
                this.ctEl.text(this.getItemsNo());
            },
            updateField: function(){
                var content = '';

                if(this.cart){
                    for (var i in this.cart) {
                        content += 'From: ' + this.cart[i].from + ' - ' +  this.cart[i].name + ' <br>';
                    }  
                    this.area.val(content);
                }else{
                    this.area.val('');
                }
            },
            getItemsNo: function(){
                return (this.cart ? Object.keys(this.cart).length : 0);
            },
            setOption: function(opt){

                this.occurs = 0;
                this.pNum = $('.form-amount')[0].value;
                var newName = this.pNum ? this.pNum + ' x ' + this.prod : '1 x ' + this.prod;
                
                if(this.opts.length){
                    for (var i in this.opts) {
                        if(this.opts[i].name == (this.prod)){
                            this.occurs = 1;
                            
                            var oldNum = parseInt(this.opts[i].num);
                            var oldName = oldNum ? oldNum + ' x ' + this.prod : '1 x ' + this.prod;
                            
                            this.ctrl.removeOption(opt);
                            this.ctrl.removeItem(oldName);
                            // this.ctrl.removeItem(this.prod);
                            
                            // set new value
                            this.opts[i].nid = newName;
                            this.opts[i].num = this.pNum;

                            // this.ctrl.addOption(opt);
                            // this.ctrl.addItem(this.prod);

                        }
                    }    
                }

                // selectize
                // if(this.occurs == 0){
                    this.ctrl.addOption(opt);
                // }
                
                this.ctrl.addItem(newName);
                
                // local stiorage
                if(this.occurs == 0){
                    this.opts.push(opt);
                }
                localStorage.setItem('opts', JSON.stringify(this.opts));
            },
            getOptions: function(){
                var opts = [];

                if(this.opts.length){
                    for (var i in this.opts) {
                        opts.push(this.opts[i]);
                    }
                }else{
                    localStorage.setItem('opts', JSON.stringify(opts));
                }

                return opts;
            },
            clearOptions: function(){

            },
            emptyCart: function(){
                var cart = {};
                localStorage.setItem('cart', JSON.stringify(cart));
            }
        },

        init: function () {

            
            for (var key in ABT) {
                if (ABT[key] && typeof ABT[key].init === typeof Function) {
                    ABT[key].init();
                }
            }

            for (var key in ABT.mt) {
                window.mt[key] = ABT.mt[key];
            }

        }
    }
   
    window.setTimeout(function(){
        ABT.init();
    }, 500);

    // $(document).on('ready', function () {});


})(jQuery);
